<template>
  <section>
    <validation-observer ref="rules">
    <!-- first floor -->
    <b-row>
      <b-col
        cols="12"
      >
        <b-card no-body>
          <b-card-body class="ll-cbody">
            <!-- user-avator -->
            <div class="d-flex justify-content-start">
              <b-avatar
                :src="userInfo.coverImg"
                :text="defaultName"
                variant="light-info"
                size="104px"
                rounded
              />
              <div class="d-flex flex-column ml-1 ll-flex">
                <div class="mb-1">
                  <h2 class="mb-0">
                    {{userInfo.userName}}
                  </h2>
                </div>
                <div class="d-flex flex-wrap">
                  <b-button
                    variant="info"
                    @click="$refs.refInputEl.click()"
                  >
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="inputImageRenderer($event)"
                      accept="image/jpeg, image/png, image/jpg"
                    >
                    <span class="d-none d-sm-inline">{{ $t('Change Avata') }}</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                  <b-form-file
                    v-model="avata"
                    accept="image/*"
                    v-if="false"
                  />
                  <!-- <b-button variant="info">
                    Change avata
                  </b-button> -->
                </div>
              </div>
              <b-form-checkbox
                v-model="userInfo.isEnabled"
                class="custom-control-info ll-checkbox"
                v-if="isCan"
              >
                {{ $t('Active') }}
              </b-form-checkbox>
              <!-- <div class="ll-tag">
                <b-badge :variant="userInfo.isEnabled ? 'light-info' : 'light-danger'" class="ll-badge">
                  {{userInfo.isEnabled ? 'Active' : 'Not Active'}}
                </b-badge>
              </div> -->
            </div>
            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('Username')"
                  label-for="username"
                >
                  <b-form-input
                    v-model="userInfo.userName"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label=""
                  label-for="accountType"
                >
                <validation-provider
                  #default="{ errors }"
                  name="accountType"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Account Type') }}</label>
                  <v-select
                    v-model="accountType"
                    :options="accountList"
                    label="name"
                    @input="selAccount"
                    placeholder=""
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('Creation Date')"
                  label-for="creatDate"
                >
                  <flat-pickr
                    v-model="userInfo.createAt"
                    class="form-control ll-pickr"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d H:i:ss'}"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- second floor -->
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Persional Info') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="4">
            <b-form-group
              label=""
              label-for="fullName"
            >
            <validation-provider
              #default="{ errors }"
              name="firstName"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('First Name') }}</label>
              <b-form-input
                id="fullName"
                v-model="userInfo.firstName"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label=""
              label-for="otherName"
            >
            <validation-provider
              #default="{ errors }"
              name="lastname"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Last Name') }}</label>
              <b-form-input
                id="otherName"
                v-model="userInfo.lastName"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label=""
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <label class="ll-boldText">{{ $t('Email') }}</label>
                <b-form-input
                  id="email"
                  v-model="userInfo.email"
                />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              :label="$t('Department')"
              label-for="department"
            >
              <b-form-input
                id="department"
                v-model="userInfo.departmentName"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              :label="$t('Phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userInfo.phone"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label=""
              label-for="company"
            >
            <validation-provider
              #default="{ errors }"
              name="company"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Company') }}</label>
              <v-select
                v-model="userInfo.orgName"
                :options="companyList"
                label="name"
                @input="getCom"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              :label="$t('Merchant(seller)code')"
              label-for="merchantCode"
            >
             <b-form-input
                id="merchantCode"
                v-model="userInfo.merchantCode"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- third floor -->
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Permission') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="4">
            <b-form-group
              label=""
              label-for="role"
            >
            <validation-provider
              #default="{ errors }"
              name="role"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Role') }}</label>
              <v-select
                :options="roleList"
                label="name"
                @input="getRole"
                multiple
                v-model="roles"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <!-- 谷歌浏览器自动填充bug -->
          <b-col md="4">
            <b-form-input type="text" style="height:0;width:0;padding:0;border:0;"></b-form-input>
          </b-col>
        </b-row>
        <!-- <b-row
          class="mb-2"
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col md="3">
            <v-select
              :options="roleList"
              label="name"
              @input="getRole"
              multiple
              v-model="roles"
            >
            </v-select>
          </b-col>
          <b-col md="4">
            <b-button
              variant="outline-danger"
              class="mt-0"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add New</span>
        </b-button> -->
      </b-card-body>
    </b-card>

    <!-- submit btn -->
    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click="submit"
      >
        {{ $t('Submit') }}
      </b-button>
    </div>
    </validation-observer>
  </section>
</template>

<script>
import {
  BRow, BCol, BAvatar, BButton, BFormGroup, BFormInput, BCardHeader, BCardTitle,
  BCard, BFormFile, BBreadcrumb, BBreadcrumbItem,
  BCardBody, BBadge, BDropdown, BDropdownItem, BImg, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BBadge,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,
    flatPickr,
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    uId: String,
  },
  data() {
    return {
      userInfo: {
        company: '',
        coverImg: '',
        createAt: '',
        createdBy: '',
        createdName: '',
        departmentName: '',
        email: '',
        isEnabled: '',
        isLocked: '',
        lastLoginAt: '',
        mfaMethod: '',
        phone: '',
        phoneAreaCode: '',
        updatedAt: '',
        updatedBy: '',
        updatedName: '',
        userExpireAt: '',
        userName: '',
        firstName: '',
        lastName: '',
        roleId: [],
        userId: null,
        organizationId: null,
        accountType: '',
        merchantCode: '',
      },
      avata: [],
      roleList: [],
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 1,
      organizationId: '',
      companyList: [],
      userList: [],
      refInputEl: null,
      defaultName: '',
      roles: null,
      accountList: [
        { name: 'User Account', key: 'UA' },
        { name: 'System Account', key: 'SA' },
      ],
      accountType: '',
      required,
      email,
      isCan: null,
    }
  },
  watch: {
    uId(val) {
      this.uId = val
      this.getUserDetail()
    },
  },
  created() {},
  mounted() {
    this.getUserDetail()
    this.getCompany()
    this.queryRoleList()
    this.ability()
  },
  methods: {
    ability() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      const arr = []
      userData.roleDtos.map(i =>{
        arr.push(i.name)
      })
      const name = 'Super Admin'
      let flag= arr.findIndex(i => i === name)
      if (flag !== -1) {
        this.isCan = true
      } else {
        this.isCan = false
      }
    },
    getUserDetail() {
      this.$http.get('/user/getUserInfoById', { params: { userId: this.uId } }).then(res => {
        // console.log('sss', res.data.code)
        const info = res.data.data
        this.userInfo = info
        this.organizationId = info.organizationId
        if (this.userInfo.firstName !== null || this.userInfo.lastName !== null) {
          this.defaultName = this.userInfo.firstName.substr(0,1) + this.userInfo.lastName.substr(0,1)
        } else {
          this.defaultName = this.userInfo.userName.substr(0,1)
        }
        this.roles = info.roleList
        if (info.accountType === 'UA') {
          this.accountType = 'User Account'
        } else if (info.accountType === 'SA') {
          this.accountType = 'System Account'
        } else {
          this.accountType = info.accountType
        }
        let arr = []
        info.roleList.map(i=>{
          arr.push(i.roleId)
        })
        this.userInfo.roleId = arr
        // this.userInfo.roleId = info.roleIds
      })
    },
    submit() {
      const obj = {}
      obj.userId = this.uId
      obj.email = this.userInfo.email
      obj.phoneAreaCode = this.userInfo.phoneAreaCode
      obj.phone = this.userInfo.phone
      obj.firstName = this.userInfo.firstName
      obj.lastName = this.userInfo.lastName
      obj.department = this.userInfo.department
      obj.coverImg = this.userInfo.coverImg
      obj.status = this.userInfo.status
      obj.isEnabled = this.userInfo.isEnabled
      obj.organizationId = this.userInfo.organizationId
      obj.roleIds  = this.userInfo.roleId
      obj.accountType = this.userInfo.accountType
      obj.merchantCode = this.userInfo.merchantCode
      obj.userName = ''
      this.$http.post('/user/updateInfo', obj).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User profile updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          const userData = JSON.parse(localStorage.getItem('userInfo'))
          if (this.$route.params.userId == userData.userId) {
            this.$router.push({ name: 'auth-login' })
          }
          // this.$router.push({ name: 'user-list' })
        }
      })
    },
    inputImageRenderer(e) {
      const file = e.target.files[0]
      const filesize = file.size
      if (filesize > 2101440) {
        // 图片大于2MB
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Images must not exceed 2MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        // 转base64
        const imgcode = e.target.result
        this.userInfo.coverImg=imgcode
      }
    },
    queryRoleList() {
      this.$http.get('/admin/settings/role/roleList').then(res => {
        // console.log(res.data.data)
        this.roleList = res.data.data
      })
    },
    getRole(t) {
      const arr = []
      t.map(item => {
        arr.push(item.roleId)
      })
      this.userInfo.roleId = arr
    },
    getCompany() {
      this.$http.get('admin/settings/organization/findAllOrganization').then(res => {
        this.companyList = res.data.data
      })
    },
    getCom(c) {
      this.userInfo.organizationId = c.id
    },
    selAccount(a) {
      this.userInfo.accountType = a.key
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.$nextTick(() => {
        // this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
      })
    },
  },
}
</script>
<style scoped>
.ll-rbtn{
  margin-bottom: 10px;
  text-align: center;
}
.ll-rbtn button{
  width: 135px;
}
.ll-cbody{
  min-height: 215px;
}
.ll-flex{
  justify-content: space-around;
  width: 90%;
}
.justify-content-start{
  margin-bottom: 10px;
}
.ll-tag, .ll-dropdown{
  width: 100%;
  text-align: right;
  margin-top: 15px;
}
.ll-dropdown .dropdown{
  width: 160px;
}
.ll-dropdown .ll-btn{
  width: 130px;
}
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-pickr{
  cursor: no-drop;
  background-color: #efefef;
}
.ll-checkbox{
  text-align: right;
}
</style>
<style>
[dir] .custom-file {
  height: 34px;
}
</style>
